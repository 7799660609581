<template>
  <div class='md-layout'>
    <div class='md-layout-item'>
      <form>
        <md-card>
          <md-card-header
            class='md-card-header-icon'
            :class='getClass(headerColor)'
          >
            <div class='card-icon'>
              <template v-if="oper==='add'">
                <md-icon>post_add</md-icon>
              </template>
              <template v-else>
                <md-icon>mode_edit</md-icon>
              </template>

            </div>
            <h4 class='title'>
              {{ $t(`plan.${oper}`) }}
              <small></small>
            </h4>
          </md-card-header>
          <md-card-content>
            <div class='md-layout md-size-50'>
              <div class='md-layout-item md-small-size-100 md-size-50'>
                <div class='md-layout-item md-small-size-100 md-size-50'>
                  <md-field>
                    <label>
                      {{ $t(`plan.plan_name`) }}
                    </label>
                    <md-input
                      v-model='plan.plan_name'
                      :type="'name'"
                      :data-vv-name="'name'"
                      @input="onFieldChange('name')"
                    ></md-input>
                  </md-field>
                  <md-field>
                    <label>
                      {{ $t(`plan.asset_type`) }}
                    </label>
                    <md-select
                      v-model='plan.asset_type_ids'
                      @input="onFieldChange('asset_type')"
                      multiple
                      :disabled='isPlanMucTypeHdm4'
                    >
                      <md-option v-for='(asset, index) in asset_type_list' :key='`${asset.name}+${index}`'
                                 :value='asset.id'
                                 :disabled='["tunnel", "culvert"].includes(asset.name)'
                      >
                        {{ $t(`asset_type.${asset.name}`) }}
                      </md-option>
                    </md-select>
                  </md-field>
                  <md-field>
                    <label>
                      {{ $t(`plan.muc_name`) }}
                    </label>

                    <md-select
                      v-model='plan.calc_id'
                      @input="onFieldChange('muc_name')"
                      :disabled='isPlanMucTypeHdm4'
                    >
                      <md-option v-for='(muc, index) of muc_list'
                                 :key='`${muc.muc_id}+${index}`' :value='muc.muc_id'
                                 :disabled='muc.muc_hdm4_type'>
                        {{
                          muc.muc_name
                        }}
                      </md-option>
                    </md-select>

                  </md-field>


                  <md-field>
                    <label>
                      {{ $t(`plan.subnetwork`) }}
                    </label>
                    <md-select
                      v-model='plan.subnetwork_id'
                      @input="onFieldChange('subnetwork')"
                      :disabled='isPlanMucTypeHdm4'
                    >
                      <md-option v-for='item of subnetwork_list' :key='item.subnetwork_id'
                                 :value='item.subnetwork_id'>
                        {{ item.subnetwork_name }}
                      </md-option>
                    </md-select>
                  </md-field>
                  <md-field>
                    <label>
                      {{ $t(`plan.budget`) }}
                    </label>
                    <md-input v-model='plan.budget' @input="onFieldChange('budget')"></md-input>
                  </md-field>
                  <template v-if='!isPlanMucTypeHdm4'>
                    <div class='text-gray' :style="{ paddingTop: 10+'px',fontSize: 1.3 + 'rem'} ">
                      {{ $t(`plan.budget_allocation`) }}
                    </div>
                    <div class='typo'>
                    <span class='text-danger' v-show='isBudgetAllocationSumWrong'>
                      {{ $t('plan.budget_allocation_should_be_100%') }}
                    </span>
                    </div>

                    <md-field>
                      <label>
                        {{ $t(`plan.budget_routine`) }}
                      </label>
                      <md-input v-model='alloc_routine' type='number' :disabled='this.plan_status'
                                @input="onFieldChange('alloc_routine')"></md-input>
                    </md-field>
                    <md-field>
                      <label>
                        {{ $t(`plan.budget_repair`) }}
                      </label>
                      <md-input v-model='alloc_repair' type='number' :disabled='this.plan_status'
                                @input="onFieldChange('alloc_repair')"></md-input>
                    </md-field>
                    <md-field>
                      <label>
                        {{ $t(`plan.budget_major_repair_replacement_work`) }}
                      </label>
                      <md-input v-model='alloc_major_repair' type='number' :disabled='this.plan_status'
                                @input="onFieldChange('alloc_major_repair')"></md-input>
                    </md-field>
                  </template>
                  <md-switch v-model='plan_status' v-if='!isNewPlan' @change='statusToggle'>
                    {{ plan.status === 0 ? $t('plan.status_draft') : $t('plan.status_final') }}
                  </md-switch>


                </div>
              </div>
              <div class='md-layout-item md-size-100 text-right'>
                <md-button
                  class='md-success'
                  native-type='submit'
                  @click.native.prevent='validate'
                  :disabled='nothingSave || (isBudgetAllocationSumWrong && !isPlanMucTypeHdm4)'
                >
                  {{ $t('button.save') }}
                </md-button>
                <md-button class='md-accent' @click.stop.prevent='onCancel'>
                  {{ $t('button.close') }}
                </md-button>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </form>
    </div>
  </div>
</template>
<script>
import {mapState} from 'vuex'
//import {SlideYDownTransition} from 'vue2-transitions'
import Swal from 'sweetalert2'

export default {
  name: 'plan-profile-form',
  props: {
    headerColor: {
      type: String,
      default: ''
    },
    oper: String
  },
  watch: {
    selectedAssetTypeNames(newVal) {
      if (['bridge', 'tunnel'].some(type => newVal.includes(type))) {
        //this.plan.calc_id = null
      }
    }
  },
  components: {
    //SlideYDownTransition
  },
  async created() {
    this.asset_type_list = await this.$store.dispatch('LOAD_ASSET_TYPE_LIST', this.domain)
    this.maintenance_type_list = await this.$store.dispatch('LOAD_MAINTENANCE_TYPE_LIST', this.domain)
    this.muc_full_list = await this.$store.dispatch('LOAD_UNIT_COSTS_LIST')
    this.subnetwork_list = await this.$store.dispatch('LOAD_SUBNETWORK_LIST', this.domain)
    this.plan.asset_type = this.asset_type
    if (this.oper === 'upd') {
      const plan = await this.$store.dispatch('GET_PLAN_BY_ID', +this.pid)
      plan.budget_allocation = plan.budget_allocation ? plan.budget_allocation : {}
      this.plan = {...plan}
      this.alloc_routine = plan.budget_allocation.routine_maintenance ? plan.budget_allocation.routine_maintenance : 0
      this.alloc_periodic = plan.budget_allocation.periodic_work ? plan.budget_allocation.periodic_work : 0
      this.alloc_rehabilitation = plan.budget_allocation.rehabilitation_work ? plan.budget_allocation.rehabilitation_work : 0
      this.alloc_repair = plan.budget_allocation.repair_work ? plan.budget_allocation.repair_work : 0
      this.alloc_major_repair = plan.budget_allocation.major_repair_replacement_work ? plan.budget_allocation.major_repair_replacement_work : 0
      this.plan_status = this.plan.status === 0 ? false : true
    }
  },
  data() {
    return {
      pid: this.$route.params.pid,
      domain: this.$route.params.domain,
      nothingSave: true,
      plan_status: false,
      budgetAllocationByAssetType: {},
      plan: {
        name: '',
        asset_type_ids: [],
        maintenance_type_id: undefined,
        calc_name: 'none',
        calc_id: null,
        asset_collection_name: 'none',
        subnetwork_id: undefined,
        budget: 0,
        budget_allocation: {},
        status: 0
      },
      alloc_routine: 0,
      alloc_periodic: 0,
      alloc_rehabilitation: 0,
      alloc_repair: 0,
      alloc_major_repair: 0,
      priority_calc_list: ['manual', 'auto'],
      asset_type_list: [],
      maintenance_type_list: [],
      muc_full_list: [],
      subnetwork_list: []
    }
  },
  methods: {
    statusToggle(val) {
      if (val === true) {
        this.plan.status = 1
      } else {
        this.plan.status = 0
      }
    },
    onFieldChange() {
      this.nothingSave = false
    },
    onCancel() {
      this.$router.go(-1)
    },
    getClass: function() {
      //return 'md-card-header-' + headerColor + ''
    },
    async validate() {
      const alert = {
        type: 'success',
        text: '',
        footer: ''
      }

      if (this.plan.calc_id === 'null') {
        this.plan.calc_id = null
      }

      const {
        plan_name,
        asset_type_ids,
        maintenance_type_id,
        calc_id,
        subnetwork_id,
        budget,
        status
      } = this.plan

      const budget_allocation = {}

      if (this.alloc_routine > 0) {
        budget_allocation['routine_maintenance'] = this.alloc_routine
      }
      if (this.alloc_repair > 0) {
        budget_allocation['repair_work'] = this.alloc_repair
      }
      if (this.alloc_major_repair > 0) {
        budget_allocation['major_repair_replacement_work'] = this.alloc_major_repair
      }

      try {
        if (this.oper === 'add') {
          await this.$store.dispatch('ADD_PLAN', {
            plan_name,
            asset_type_ids,
            maintenance_type_id,
            calc_id,
            subnetwork_id,
            calc_type: 'muc',
            budget: Number(budget),
            budget_allocation,
            status
          })
          alert.text = this.$t(`plan.record_was_added`)
        } else if (this.oper === 'upd') {
          await this.$store.dispatch('UPD_PLAN', {
            plan_id: this.pid,
            data: {
              plan_name,
              asset_type_ids,
              maintenance_type_id,
              calc_id,
              subnetwork_id,
              budget: Number(budget),
              status,
              budget_allocation
            }
          })
          alert.text = this.$t(`plan.record_was_updated`)
        } else {
          console.log('Unknown operation')
        }

        this.nothingSave = true
        Swal.fire(alert).then(() => {
          //this.touched.category = false
          this.$nextTick(() => this.$validator.reset())
        })
      } catch (err) {
        console.log(err)
        alert.type = 'error'
        if (this.oper === 'add') {
          alert.text = this.$t(`plan.record_was_not_added`)
        } else if (this.oper === 'upd') {
          alert.text = this.$t(`plan.record_was_not_updated`)
        }
        //alert.footer = this.$t(err)
        Swal.fire(alert)
      }
    }
  },
  computed: {
    ...mapState({categoryByAssetsType: state => state.Features.categoryByAssetsType}),

    fields() {
      return ['asset_type', 'maintenance_type',
        'priority_calc', 'calc_name', 'asset_collection_name']
    }
    ,
    asset_type() {
      return this.categoryByAssetsType[this.domain]
    }
    ,
    muc_list() {
      return [{muc_id: null, muc_name: 'none'}, ...this.muc_full_list.map(muc => {
        return {muc_id: muc.muc_id, muc_name: muc.muc_name, muc_hdm4_type: muc.hdm4_type}
      })] //.filter(muc => muc.category === this.asset_type)
    }
    ,
    isNewPlan() {
      return this.oper === 'add'
    },
    selectedAssetTypeNames() {
      return this.plan.asset_type_ids.length > 0 ?
        this.asset_type_list.filter(type =>
          this.plan.asset_type_ids.includes(type.id)
        ).map(type => type.name) : []
    },
    isBudgetAllocationSumWrong() {
      const sums = Number(this.alloc_major_repair) + Number(this.alloc_repair) +
        Number(this.alloc_routine) + Number(this.alloc_periodic) + Number(this.alloc_rehabilitation)
      return sums !== 100
    },
    isPlanMucTypeHdm4() {
      return this.plan.calc_type === 'hdm4'
    }
  }
}
</script>
<style>
.md-button + .md-button {
  margin-left: 10px;
}

.typo {
  position: relative;
  width: 100%;
  height: 40px;
}
</style>
